import { isEmpty, isNil } from 'ramda';
import { AirepFull } from '../../types/airepTypes';
import { logApiError } from '../../utils/handleApiError';
import { Alert } from '../../utils/snackBarContext';
import { updateAirep } from '../fetchActions';

export const handleRemove = async (
  airep: AirepFull,
  refetchAireps: () => void,
  addAlert?: (alert: Alert) => void,
) => {
  try {
    const payload = {
      _id: airep._id,
      expire: new Date(),
      cancelled: new Date(),
    };
    const response = await updateAirep(payload);
    if (isNil(response) || isEmpty(response)) {
      throw new Error('deactivation failed !');
    }
  } catch (error) {
    logApiError(error);
    addAlert && addAlert({ type: 'error', message: `deactivation failed` });
  } finally {
    refetchAireps();
  }
};
