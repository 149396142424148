export type Actions = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'ADMIN';

export type UserDetails = {
  id: string;
  username: string;
  name: string;
  type: 'CWP' | 'FLOWMANAGER' | 'SUPERVISOR' | 'TECHNICALSUPERVISOR' | 'OTHER';
  center: string;
  privileges: Actions[];
};

export type MyUser = UserDetails & {
  canGet: () => boolean;
  canPost: () => boolean;
  canEdit: () => boolean;
  canDelete: () => boolean;
  canAdmin: () => boolean;
};

export const getMyUser = (user: UserDetails) => {
  const id = user.id;
  const username = user.username;
  const name = user.name;
  const type = user.type;
  const center = user.center;
  const privileges = user.privileges;
  const canGet = (): boolean =>
    user?.privileges ? user.privileges.includes('GET') : false;
  const canPost = (): boolean =>
    user?.privileges ? user.privileges.includes('POST') : false;
  const canEdit = (): boolean =>
    user?.privileges ? user.privileges.includes('PUT') : false;
  const canDelete = (): boolean =>
    user?.privileges ? user.privileges.includes('DELETE') : false;
  const canAdmin = (): boolean =>
    user?.privileges ? user.privileges.includes('ADMIN') : false;
  return {
    id,
    username,
    name,
    type,
    center,
    privileges,
    canGet,
    canPost,
    canEdit,
    canDelete,
    canAdmin,
  };
};
