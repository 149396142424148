// import './whyDidYouUpdate';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import 'fontsource-roboto';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppUpdateNotifier } from './components/utilitaryComponents/AppUpdateNotifier';
import { getApiBaseURL } from './constants/baseURL';
import './index.css';
import { Main } from './Main';
import * as serviceWorker from './serviceWorker';
import { darkTheme } from './theming/themes';
import { AuthProvider } from './UserContext/UserContext';
import { SnackBarProvider as CustomSnackBar } from './utils/snackBarContext';

console.log(
  'current version is : ',
  process.env.REACT_APP_VERSION ?? 'unspecified',
);

console.log('current sha is : ', process.env.REACT_APP_SHA ?? 'unspecified');

console.log(
  'current environment is : ',
  process.env.REACT_APP_ENVIRONMENT ?? 'unspecified',
);

const urlApi = new URL(getApiBaseURL());

// Sentry.init({
//   dsn: 'https://c3a02a590cc541c5a29c0c260083d349@o491616.ingest.sentry.io/5557466',
//   autoSessionTracking: true,
//   release: 'airep-special@' + (process.env.REACT_APP_VERSION ?? 'unspecified'),
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: [urlApi.host],
//     }),
//   ],
//   environment: process.env.REACT_APP_ENVIRONMENT ?? 'unspecified',
//   tracesSampleRate:
//     process.env.REACT_APP_ENVIRONMENT === 'development' ? 1 : 0.1,
// });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ThemeProvider theme={darkTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AuthProvider>
        <Router>
          <CustomSnackBar>
            <SnackbarProvider maxSnack={3}>
              <CssBaseline />
              <Main />
              <Toaster
                position="bottom-center"
                toastOptions={{
                  duration: 10000,
                }}
              />
              <AppUpdateNotifier />
            </SnackbarProvider>
          </CustomSnackBar>
        </Router>
      </AuthProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
