import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { Params } from '../../apiActions/fetchActions/useFetch';

type Props = {
  updateParams: React.Dispatch<React.SetStateAction<Params>>;
};

const TIME_SELECTION = [
  {
    label: '2h',
    value: '7200',
  },
  {
    label: '24h',
    value: '86400',
  },
  {
    label: 'all',
    value: '0',
  },
] as const;

export type TimeSelectionValues = typeof TIME_SELECTION[number]['value'];

export const TimeSelector: React.FC<Props> = ({ updateParams }) => {
  const [value, setValue] = React.useState<TimeSelectionValues>(
    TIME_SELECTION[0].value,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement)
      .value as TimeSelectionValues;
    setValue(value);
    updateParams((params) => ({
      ...params,
      fromsecondssago: value === '0' ? null : value,
    }));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="age"
          name="age"
          value={value}
          onChange={handleChange}
        >
          <Box>
            {TIME_SELECTION.map((selection) => (
              <FormControlLabel
                key={`key-${selection.label}`}
                label={selection.label}
                value={selection.value}
                control={<Radio />}
              />
            ))}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
