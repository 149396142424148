import React, { useContext, useState } from 'react';
import { MyUser } from './myUser';

const UserContext = React.createContext<{
  myUser: MyUser | null;
  setMyUser: React.Dispatch<React.SetStateAction<MyUser | null>>;
}>({ myUser: null, setMyUser: () => {} });

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [myUser, setMyUser] = useState<MyUser | null>(null);
  return (
    <UserContext.Provider value={{ myUser, setMyUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useAuth() {
  const { myUser, setMyUser } = useContext(UserContext);
  return { myUser, setMyUser };
}
