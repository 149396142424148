import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useInterval } from '../../utils/useInterval';

export const Clock: React.FC<{}> = () => {
  const [date, setDate] = useState(new Date());

  useInterval(() => {
    setDate(new Date());
  }, 128);

  return (
    <Typography variant="h4">
      {date.toLocaleDateString('en-GB', { timeZone: 'UTC' })} -{' '}
      {date.toLocaleTimeString('en-GB', { timeZone: 'UTC' })}
    </Typography>
  );
};
