import { Box, Drawer, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useFetch } from './apiActions/fetchActions';
import { AirepDetails } from './components/AirepDetails/AirepDetails';
import { AirepList } from './components/AirepList/AirepList';
import { AirepMap } from './components/AirepMap/Map';
import {
  TimeSelectionValues,
  TimeSelector,
} from './components/TimeSelector/TimeSelector';
import { TopBar } from './components/TopBar/TopBar';
import { getApiBaseURL } from './constants/baseURL';
import { AirepFull } from './types/airepTypes';
import { getActiveAireps } from './utils/getActiveAireps';
import { SnackBarContext } from './utils/snackBarContext';

const baseURL = getApiBaseURL();
const path = '/aireps';

const Container: React.FC = (props) => <Box height="100%" {...props} />;

const initialParams: {
  includeCancelled: 'true' | 'false';
  fromsecondssago: TimeSelectionValues;
} = {
  includeCancelled: 'true',
  fromsecondssago: '7200', // default 2 hours
};

export const App: React.FC = () => {
  const { addAlert } = useContext(SnackBarContext);
  const {
    data: airepList,
    refetch,
    updateParams,
    errorMessage,
    refetchIndex,
  } = useFetch<AirepFull[]>({
    initialBaseURL: baseURL,
    initialPath: path,
    initialParams,
  });

  const [selectedAirep, setSelectedAirep] = useState<AirepFull | null>(null);

  useEffect(() => {
    if (errorMessage) {
      addAlert({ type: 'error', message: errorMessage });
    }
  }, [addAlert, errorMessage, refetchIndex]);

  const activeAirepList = getActiveAireps(airepList);

  return (
    <>
      <TopBar />
      <Grid container component={Container}>
        <Grid item xs={4}>
          <TimeSelector updateParams={updateParams} />
          <AirepList
            airepList={airepList ?? []}
            refetch={refetch}
            setSelectedAirep={setSelectedAirep}
          />
        </Grid>
        <Grid item xs>
          <AirepMap
            refetch={refetch}
            airepList={activeAirepList ?? []}
            selectedAirep={selectedAirep}
            setSelectedAirep={setSelectedAirep}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={!!selectedAirep}
        onClose={() => setSelectedAirep(null)}
      >
        {selectedAirep && (
          <AirepDetails
            selectedAirep={selectedAirep}
            refetchAireps={refetch}
            setSelectedAirep={setSelectedAirep}
          />
        )}
      </Drawer>
    </>
  );
};
