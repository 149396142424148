import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { differenceInSeconds as dfs, format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { handleRemove } from '../../apiActions/airepHandlers';
import { handleDelete } from '../../apiActions/airepHandlers/handleDelete';
import { getAirepStatus } from '../../shared/getAirepStatus';
import { readablePhenomenon } from '../../shared/phenomDescription';
import { AirepFull, hasAltitude, hasFlightLevel } from '../../types/airepTypes';
import { useAuth } from '../../UserContext/UserContext';
import { SnackBarContext } from '../../utils/snackBarContext';
import { useInterval } from '../../utils/useInterval';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: 8,
    },
    table: {
      width: 400,
    },
    firstCell: {
      color: grey[500],
      textAlign: 'right',
    },
    button: {
      margin: '10px',
    },
  }),
);

type Props = {
  selectedAirep: AirepFull;
  refetchAireps: () => void;
  setSelectedAirep: React.Dispatch<React.SetStateAction<AirepFull | null>>;
};

export const AirepDetails: React.FC<Props> = ({
  selectedAirep,
  refetchAireps,
  setSelectedAirep,
}) => {
  const classes = useStyles();
  const { addAlert } = useContext(SnackBarContext);
  const { myUser } = useAuth();
  const [update, setUpdate] = useState(Date.now());

  useInterval(() => {
    setUpdate(Date.now());
  }, 128);

  return (
    <>
      <Box width="100%" textAlign="center" marginTop="15px" marginBottom="15px">
        <Typography variant="h5">AIREP DETAILS</Typography>
      </Box>
      <Divider />
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow key="phenom">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              PHENOM :
            </TableCell>
            <TableCell align="left">
              {readablePhenomenon(selectedAirep.phenomenon)}
            </TableCell>
          </TableRow>
          <TableRow key="state">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              STATUS :
            </TableCell>
            <TableCell align="left">
              <Typography color="textSecondary" variant="body1">
                {getAirepStatus(selectedAirep)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="date">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              DATE :
            </TableCell>
            <TableCell align="left">
              {format(new Date(selectedAirep.reported_time), 'dd/MM/yyyy')}
            </TableCell>
          </TableRow>
          <TableRow key="time">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              TIME :
            </TableCell>
            <TableCell align="left">
              {format(new Date(selectedAirep.reported_time), 'HH:mm:ss')}
            </TableCell>
          </TableRow>
          <TableRow key="callsign">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              CALLSIGN :
            </TableCell>
            <TableCell align="left">
              {selectedAirep.aircraft_callsign}
            </TableCell>
          </TableRow>
          <TableRow key="type">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              TYPE :
            </TableCell>
            <TableCell align="left">
              {selectedAirep.aircraft_type ?? 'NIL'}
            </TableCell>
          </TableRow>
          {hasFlightLevel(selectedAirep.flightLevelOrAltitude) && (
            <>
              <TableRow key="flightLevel1">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  FLIGHT LEVEL 1 :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.flightLevel
                    .flightLevel_1 ?? 'NIL'}
                </TableCell>
              </TableRow>
              <TableRow key="flightLevel2">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  FLIGHT LEVEL 2 :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.flightLevel
                    .flightLevel_2 ?? 'NIL'}
                </TableCell>
              </TableRow>
              <TableRow key="flightLevelTendancy">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  TENDANCY :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.flightLevel.tendancy ??
                    'NIL'}
                </TableCell>
              </TableRow>
            </>
          )}
          {hasAltitude(selectedAirep.flightLevelOrAltitude) && (
            <>
              <TableRow key="altitude1">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  ALTITUDE 1 :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.altitude.altitude_1 ??
                    'NIL'}
                </TableCell>
              </TableRow>
              <TableRow key="altitude2">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  ALTITUDE 1 :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.altitude.altitude_2 ??
                    'NIL'}
                </TableCell>
              </TableRow>
              <TableRow key="unit">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  ALTITUDE 2 :
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.altitude.unit ?? 'NIL'}
                </TableCell>
              </TableRow>
              <TableRow key="unit">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.firstCell}
                >
                  ALTITUDE TENDANCY:
                </TableCell>
                <TableCell align="left">
                  {selectedAirep.flightLevelOrAltitude.altitude.tendancy ??
                    'NIL'}
                </TableCell>
              </TableRow>
              )
            </>
          )}
          <TableRow key="longitude">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              LONGITUDE :
            </TableCell>
            <TableCell align="left">
              {selectedAirep.location.coordinates[0]}
            </TableCell>
          </TableRow>
          <TableRow key="latitude">
            <TableCell component="th" scope="row" className={classes.firstCell}>
              LATITUDE :
            </TableCell>
            <TableCell align="left">
              {selectedAirep.location.coordinates[1]}
            </TableCell>
          </TableRow>
          {selectedAirep.remark && (
            <TableRow key="remark">
              <TableCell
                component="th"
                scope="row"
                className={classes.firstCell}
              >
                REMARK :
              </TableCell>
              <TableCell align="left">{selectedAirep.remark}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box width="100%" textAlign="center" marginTop="15px" marginBottom="15px">
        {myUser?.canEdit &&
          getAirepStatus(selectedAirep) === 'ACTIVE' &&
          dfs(Date.now(), new Date(selectedAirep.created)) < 25 && (
            <>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={(event) => {
                  handleRemove(selectedAirep, refetchAireps, addAlert);
                  setSelectedAirep(null);
                }}
              >
                {`UNDO (${
                  25 - dfs(Date.now(), new Date(selectedAirep.created))
                })`}
              </Button>
            </>
          )}

        {myUser?.canDelete() && (
          <Button
            variant="outlined"
            className={classes.button}
            onClick={(event) => {
              handleDelete(selectedAirep._id, refetchAireps, addAlert);
              setSelectedAirep(null);
            }}
          >
            DELETE AIREP
          </Button>
        )}
      </Box>
    </>
  );
};
