import {
  Button,
  LinearProgress,
  makeStyles,
  Snackbar,
  Theme,
} from '@material-ui/core';
import axios from 'axios';
import { addMilliseconds, differenceInSeconds } from 'date-fns';
import React, { useEffect, useReducer, useState } from 'react';
import { AutoUpdate } from '../../utils/AutoUpdate';
import { logApiError } from '../../utils/handleApiError';
import { timeIsLaterThan } from '../../utils/timeUtilities';
import { useInterval } from '../../utils/useInterval';

export async function getLatestSha(): Promise<string | null> {
  if (process.env.NODE_ENV === 'development') return 'dev';
  try {
    const res = await axios.get('version.json');
    return res.data['sha'] || null;
  } catch (err) {
    logApiError(err);
    console.error('[AppUpdateNotifier] Error fetching commit sha');
    return null;
  }
}

const CHECK_PERIOD = 5 * 60 * 1000;

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    marginTop: theme.spacing(1),
  },
}));

const REBOOT_DELAY = 10 * 1000;

type State =
  | {
      rebootTime: Date;
      rebootNotifiedTime: Date;
    }
  | { rebootTime: null; rebootNotifiedTime: null };

type Action = { type: 'notify' } | { type: 'cancel' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'notify': {
      return {
        rebootNotifiedTime: new Date(),
        rebootTime: addMilliseconds(new Date(), REBOOT_DELAY),
      };
    }
    case 'cancel': {
      return {
        rebootNotifiedTime: null,
        rebootTime: null,
      };
    }
  }
}

export const AppUpdateNotifier: React.FC<{}> = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, {
    rebootTime: null,
    rebootNotifiedTime: null,
  });
  const [currentVersion, setCurrentVersion] = useState<null | string>(null);

  useEffect(() => {
    let isMounted = true;
    getLatestSha().then((res) => {
      if (!isMounted) {
        return;
      }
      console.log(`[AppUpdateNotifier]: Current bundle is ${res}`);
      setCurrentVersion(res);
    });

    return () => {
      isMounted = false;
    };
  }, [setCurrentVersion]);

  useInterval(async () => {
    // Update has already been notified, bail out
    if (state.rebootNotifiedTime) {
      return;
    }

    const availableVersion = await getLatestSha();

    if (!availableVersion || availableVersion === currentVersion) {
      if (state.rebootTime) {
        dispatch({ type: 'cancel' });
      }
      return;
    }

    console.log(
      `[AppUpdateNotifier]: Current bundle is ${currentVersion}, available bundle is ${availableVersion}`,
    );

    dispatch({ type: 'notify' });
  }, CHECK_PERIOD);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={!!state.rebootNotifiedTime}
      message={
        <AutoUpdate interval={500}>
          {() => {
            if (!state.rebootTime) {
              return null;
            }

            if (timeIsLaterThan(state.rebootTime)) {
              window.location.reload();
              return null;
            }

            const durationToReboot = differenceInSeconds(
              new Date(),
              state.rebootTime,
            );

            const progressValue =
              (100 *
                (new Date().valueOf() - state.rebootNotifiedTime.valueOf())) /
              (state.rebootTime.valueOf() - state.rebootNotifiedTime.valueOf());

            return (
              <>
                A new version of 4ME is available. The application will reboot
                in {durationToReboot} seconds.
                <LinearProgress
                  className={classes.progress}
                  variant="determinate"
                  value={progressValue}
                />
              </>
            );
          }}
        </AutoUpdate>
      }
      action={
        <>
          <Button
            color="primary"
            size="small"
            onClick={() => window.location.reload()}
          >
            Update
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              dispatch({ type: 'cancel' });
            }}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};
