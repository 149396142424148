import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: null | number) {
  const savedCallback = useRef<undefined | (() => void)>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
