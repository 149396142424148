import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { last } from 'ramda';
import React from 'react';
import { Marker } from 'react-map-gl';
import { WeatherIcons } from '../../../shared/WeatherIcons';
import { AirepFull } from '../../../types/airepTypes';

type Props = {
  airepList: AirepFull[];
  selectedAirep: AirepFull | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      border: 'solid',
      borderColor: grey[50],
      borderRadius: '15px',
      borderWidth: '1px',
      color: grey[50],
      outlineOffset: '10px',
    },
  }),
);

const Layer: React.FC<Props> = ({ airepList, selectedAirep }) => {
  const classes = useStyles();
  return (
    <>
      {airepList.map((airep) => (
        <Marker
          key={`airep-${airep._id}`}
          latitude={airep.location.coordinates[1]}
          longitude={airep.location.coordinates[0]}
        >
          <WeatherIcons phenom={airep.phenomenon} />
        </Marker>
      ))}
      {selectedAirep && (
        <Marker
          key={`airep-${selectedAirep._id}`}
          latitude={selectedAirep.location.coordinates[1]}
          longitude={selectedAirep.location.coordinates[0]}
        >
          <WeatherIcons
            phenom={selectedAirep.phenomenon}
            className={classes.icon}
          />
        </Marker>
      )}
    </>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    last(prevProps.airepList)?.updated === last(nextProps.airepList)?.updated &&
    prevProps.selectedAirep === nextProps.selectedAirep
  );
}

export const AirepLayer = React.memo(Layer, areEqual);
