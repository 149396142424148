import { blue, grey, purple, red, yellow } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { Phenomenon } from '../types/airepTypes';

export const phenomColors = {
  modTurb: yellow[300],
  sevTurb: yellow[900],
  modIcing: blue[400],
  sevIcing: blue[600],
  windshear: purple[200],
  tempInv: grey[50],
  thunderStorm: blue[400],
  cumulonimbus: blue[400],
  hail: grey[50],
  vulcano: red[600],
  sandstorm: purple[200],
  default: grey[50],
} as const;

const DEFAULT_ICON_SIZE = 15;

// Moderate turbulence
export const ModTurbIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.modTurb, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="M22.07 11.16h-2.5l-5.51-9.85a2.08 2.08 0 00-3.62 0l-5.51 9.85h-2.5a2.17 2.17 0 00-2.18 2.17v.81a2.17 2.17 0 002.18 2.17h4.35l3.66-6.54a2.08 2.08 0 013.62 0l3.66 6.54h4.35a2.17 2.17 0 002.18-2.17v-.81a2.17 2.17 0 00-2.18-2.17z" />
    </SvgIcon>
  );
};

// severe turbulence
export const SevTurbIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.sevTurb, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="M22.07 18.9h-2.5L14.06 9a2.09 2.09 0 00-3.62 0l-5.51 9.9h-2.5a2.17 2.17 0 00-2.18 2.16v.82A2.16 2.16 0 002.43 24h4.35l3.66-6.54a2.09 2.09 0 013.62 0L17.72 24h4.35a2.16 2.16 0 002.18-2.16v-.82a2.17 2.17 0 00-2.18-2.12z" />
      <path d="M19.92 7.1L13.64.82a2 2 0 00-2.78 0L4.58 7.1a1.94 1.94 0 002.74 2.74l3.18-3.18a2.78 2.78 0 011.75-.53 2.78 2.78 0 011.75.53l3.18 3.18a1.94 1.94 0 002.74-2.74z" />
    </SvgIcon>
  );
};

// moderate icing
export const ModIcingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.modIcing, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="M23.56 4.19c-.95 2.89-3.92 6.09-7.37 7.79l.59 8.61a1.49 1.49 0 01-1.39 1.6h-.29a1.51 1.51 0 01-1.31-1.39l-.54-7.8a9.35 9.35 0 01-1.81.19 9.73 9.73 0 01-1.34-.09l-.83 9A1.49 1.49 0 018 23.43a1.59 1.59 0 01-.38 0 1.5 1.5 0 01-1.35-1.63l.87-9.58a11.79 11.79 0 01-6.6-9.07 1.5 1.5 0 013-.3A8.79 8.79 0 007.46 9l.15-1.62a1.5 1.5 0 013 .28l-.23 2.46a6.35 6.35 0 001.07.09A6.4 6.4 0 0013 10l-.17-2.37a1.5 1.5 0 113-.21L16 8.7a11.61 11.61 0 004.76-5.45 1.5 1.5 0 112.84.94z" />
    </SvgIcon>
  );
};

// severe icing
export const SevIcingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.sevIcing, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="M18.9 10.27l1.64 8.45a1.52 1.52 0 01-1.19 1.76h-.29a1.5 1.5 0 01-1.47-1.21L16.18 12a11.83 11.83 0 01-2.74 1l-.17 9a1.49 1.49 0 01-1.49 1.47 1.49 1.49 0 01-1.47-1.53l.16-8.74a10 10 0 01-2.56-.61l-2.12 8.08a1.49 1.49 0 01-1.45 1.12 1.64 1.64 0 01-.38 0 1.51 1.51 0 01-1.08-1.83L5.17 11A11.72 11.72 0 01.54 3.15a1.5 1.5 0 113-.31A8.65 8.65 0 006 7.8l.5-1.92a1.5 1.5 0 012.91.75l-.77 3a7.15 7.15 0 001.85.52l.06-3.21a1.5 1.5 0 113 0l-.05 2.92a10 10 0 002.09-.94l-.51-2.61A1.5 1.5 0 1118 5.74l.22 1.15a9.7 9.7 0 002.47-3.64 1.5 1.5 0 112.85.94 13.86 13.86 0 01-4.64 6.08z" />
    </SvgIcon>
  );
};

// windshear turbulence
export const WindshearIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.windshear, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="M18.72 3.43h.05a.47.47 0 00-.15-.06.17.17 0 01.1.06z" />
      <path d="M19.61 5.06a.36.36 0 010 .11.14.14 0 010 .06.69.69 0 010 .13.56.56 0 01-.11.2l-.11.13a1.09 1.09 0 01-.29.23.56.56 0 01-.2.11.57.57 0 01-.22.07h.1l-4.12 1.5a1.52 1.52 0 01-.51.09 1.49 1.49 0 01-1.41-1 1.6 1.6 0 01-.09-.53H2a1.5 1.5 0 010-3h10.32a1.21 1.21 0 01.07-.37 1.51 1.51 0 011.89-1l4.16 1.36h.16a.47.47 0 01.15.06.37.37 0 01.14.07 1.1 1.1 0 01.38.33.47.47 0 01.09.12.78.78 0 01.11.2.14.14 0 010 .06.5.5 0 010 .13.29.29 0 010 .09 1.21 1.21 0 01.14.85zM18.49 6.26a.57.57 0 00.22-.07h-.12z" />
      <path d="M18.91 3.53a.37.37 0 00-.14-.07h.06zM19.55 4.3a.14.14 0 000-.06zM18.49 6.26a.57.57 0 00.22-.07h-.12zM18.62 3.4h.31a.37.37 0 00-.14-.07.47.47 0 00-.17.07zM19.53 4.25zM19.62 19.17a.14.14 0 010 .06.29.29 0 010 .09.9.9 0 01-.08.23 1.09 1.09 0 01-.08.15 1.18 1.18 0 01-.19.25.24.24 0 01-.09.08l-.12.08a.78.78 0 01-.2.11.81.81 0 01-.22.07h.1l-4.12 1.49a1.52 1.52 0 01-.51.09 1.49 1.49 0 01-1.41-1 1.54 1.54 0 01-.09-.52H2a1.5 1.5 0 110-3h10.32a1.16 1.16 0 01.07-.37 1.5 1.5 0 011.89-1l4.32 1.52h-.12l.22.07a1.41 1.41 0 01.24.13l.09.07a.54.54 0 01.15.15.34.34 0 01.08.09.21.21 0 010 .06 1.19 1.19 0 01.13.25.74.74 0 01.07.21.25.25 0 010 .07.13.13 0 000 .06 1.55 1.55 0 01.16.51z" />
      <path d="M19.62 19.17a.57.57 0 010 .14.29.29 0 000-.09.14.14 0 000-.05zM18.7 17.54l-.22-.07h.12zM18.49 20.38a.81.81 0 00.22-.07h-.12zM19.55 18.41a.83.83 0 010 .15.74.74 0 00-.07-.21z" />
      <path d="M19.62 19.17v.05a.14.14 0 000-.05zM18.7 17.54l-.22-.07h.12zM18.49 20.38a.81.81 0 00.22-.07h-.12zM19.55 18.41a.83.83 0 010 .15.74.74 0 00-.07-.21zM23.45 11.79a1.5 1.5 0 01-1.5 1.5H11.49a1.69 1.69 0 01-.09.53 1.51 1.51 0 01-1.41 1 1.58 1.58 0 01-.52-.09l-4.12-1.5h.1a.73.73 0 01-.21-.06.56.56 0 01-.2-.11 1.18 1.18 0 01-.32-.27l-.08-.09a.56.56 0 01-.11-.2.69.69 0 010-.13.14.14 0 010-.06.36.36 0 010-.11 1.21 1.21 0 010-.5.29.29 0 010-.09.5.5 0 010-.13.09.09 0 010-.06.78.78 0 01.11-.2.61.61 0 01.12-.16l.1-.1a1.18 1.18 0 01.14-.45.37.37 0 01.14-.07h.25L9.67 9a1.51 1.51 0 011.89 1 1.19 1.19 0 01.06.37H22a1.5 1.5 0 011.45 1.42zM4.39 11.28a.09.09 0 000 .06z" />
      <path d="M5.17 10.44a.37.37 0 00-.14.07l.12-.07zM5.45 13.24h-.16a.73.73 0 00.16 0zM4.39 11.28a.09.09 0 000 .06z" />
      <path d="M5.15 10.44h.06a.37.37 0 00-.14.07zM5.45 13.24h-.16a.73.73 0 00.16 0z" />
    </SvgIcon>
  );
};

// sandstorm
export const SandstormIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.sandstorm, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m15.6,3.3318c-1.1,-0.13011 -2.07,0.92381 -2.17,2.36809l-0.25,3.76032l2.82,0l0,2.6023l-3,0l-0.44,6.59682c-0.19,2.86253 -2.13,4.9834 -4.33,4.72317c-1.31,-0.14313 -2.41,-1.09296 -3.06,-2.43315l1.5,-1.95172c0.24,0.96285 0.9,1.7045 1.73,1.79558c1.1,0.13011 2.07,-0.92381 2.17,-2.36809l0.43,-6.36261l-3,0l0,-2.6023l3.17,0l0.27,-3.99452c0.19,-2.86253 2.13,-4.9834 4.33,-4.72317c1.31,0.14313 2.41,1.09296 3.06,2.43315l-1.5,1.95172c-0.24,-0.96285 -0.9,-1.7045 -1.73,-1.79558z" />
    </SvgIcon>
  );
};

// thunderstorm
export const ThunderstormIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.thunderStorm, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m0.625,23.28125l0.97641,-22.84375l21.96109,-0.0625l-10.11921,10.625l5.5476,7l2.89967,-3.32031l-0.2399,8.02735l-8.01724,-0.04883l3.12151,-2.61133l-9.42794,-8.07813l5.58278,-7.15625l-7.63907,-0.4375l0.91391,18.90625l-5.5596,0z" />
    </SvgIcon>
  );
};

// cumulonimbus
export const CumulonimbusIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.cumulonimbus, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m2.03244,10.84376c3.06424,0 10.83175,-0.08385 15.10744,-0.08385c7.83877,-0.16771 6.05723,-6.28896 2.06658,-7.12748c-0.21378,-3.09992 -2.56541,-4.68152 -5.48714,-2.26402c-3.84812,-2.1596 -10.68923,-0.8786 -10.90301,3.99012c-2.56541,0.33541 -3.84812,5.48524 -0.78388,5.48524z" />
      <path d="m16.15153,23.28115l0.07778,-6.94736l-1.79194,0.42917l2.88268,-2.95058l2.80477,2.89693l-2.02566,-0.32188l0.07791,6.86681l-2.02554,0.02691z" />
      <path d="m4.27663,23.15615l0.07778,-6.94736l-1.79194,0.42917l2.88268,-2.95058l2.80477,2.89693l-2.02566,-0.32188l0.07791,6.86681l-2.02554,0.02691z" />
      <path d="m10.33908,21.15617l0.07778,-6.94737l-1.79194,0.42917l2.88268,-2.95058l2.80477,2.89693l-2.02566,-0.32187l0.07791,6.86681l-2.02554,0.02691z" />
    </SvgIcon>
  );
};

// vulcano activity
export const VulcanoIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.vulcano, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m22.81241,23.03115l-22.12491,0.03115l5.12496,-12.37489l3.68747,-0.0625l-6.06245,-6.24995l2.37498,-1.93748l4.81246,5.93745l-0.87499,-7.74993l3.49997,0l-0.3125,8.37493l5.06246,-6.81244l1.99998,1.99998l-5.93745,6.37494l3.81247,0.0625l3.18747,7.87493l-4.18746,0.0625l-1.68749,-4.81246l-6.93744,0.0625l-2.37498,6.24995l15.62487,-0.0625l1.31259,3.03133z" />
    </SvgIcon>
  );
};

export const VulcanoCloudIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.vulcano, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m22.81241,23.03115l-22.12492,0.03115l5.12496,-12.37489l3.68747,-0.0625l-0.00002,-1.81248c0.14584,-9.8281 7.51301,-5.58592 7.59374,-3.29686c0.08073,2.28906 4.03644,3.32811 3.95116,-0.90234l0.01574,-3.94701l2.07825,-0.07203c0.08695,0.91284 0.02357,1.51916 0.10903,5.34404c0.04273,1.91244 -2.43982,3.5514 -3.69152,3.62314c-1.2517,0.07174 -4.27256,-0.11124 -4.30641,-2.03019c-0.03385,-1.91895 -3.10416,-3.24998 -3.12499,0.34376l0,2.62497l5.74995,0.125l3.37497,8.62493l-4.43747,-0.0625l-1.62498,-5.43746l-6.93744,0.0625l-2.37498,6.24995l15.62486,-0.0625l1.3126,3.03132z" />
    </SvgIcon>
  );
};

// temperature inversion
export const TempInvIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.tempInv, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m4.32154,23.46862l6.55334,-8.6154l-5.52944,-4.42198l4.69366,-5.5026l2.18592,1.85207l0.21351,-5.78072l-6.79573,-0.01032l2.0485,2.07491l-7.0038,7.91426l5.21656,4.25266l-4.80546,5.83933l3.22295,2.3978z" />
      <path d="m14.38584,22.71863l0.36008,-11.14073l-4.12104,0.8083l0.0625,-3.75049l11.81249,0.17669l0.0127,2.7655l-4.74126,-0.05831l0.44444,11.13223l-3.82991,0.0668z" />
      <path d="m18.22105,2.05782l-0.78374,2.00855l1.17499,2.3758l3.1333,0.18275l1.56665,-2.55856l-0.78332,-2.55856l-2.34997,-0.63282l-1.95789,1.18283z" />
    </SvgIcon>
  );
};

// hail
export const HailIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.hail, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
    >
      <path d="m23.0625,23.40625l-21.9375,-0.03125l12.5,-22.25l8.1875,18.625l-2.4375,0.0625l-6.0625,-13.375l-8,14.5625l16.9375,0l0.8125,2.40625z" />
    </SvgIcon>
  );
};

// default
export const DefaultIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ color: phenomColors.default, fontSize: DEFAULT_ICON_SIZE }}
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M512.5 829C337.702 829 196 687.298 196 512.5S337.702 196 512.5 196 829 337.702 829 512.5 687.298 829 512.5 829z m0-60C654.161 769 769 654.161 769 512.5S654.161 256 512.5 256 256 370.839 256 512.5 370.839 769 512.5 769zM483 622h46a7 7 0 0 1 7 7v46a7 7 0 0 1-7 7h-46a7 7 0 0 1-7-7v-46a7 7 0 0 1 7-7z m53.042-57.45v30h-60v-30c0-4.463 0.027-7.062 0.155-10.328 0.303-7.719 0.946-13.36 2.901-19.091 1.649-4.832 2.855-7.873 4.802-11.425 2.952-5.385 6.803-9.983 11.75-13.847 6.844-5.346 13.437-8.17 28.196-13.239 7.959-2.733 19.842-10.089 28.818-18.845C562.774 467.912 568 457.827 568 448.5c0-30.652-24.848-55.5-55.5-55.5S457 417.848 457 448.5v30h-60v-30c0-63.789 51.711-115.5 115.5-115.5S628 384.711 628 448.5c0 27.488-12.696 51.989-33.439 72.224-15.298 14.924-34.823 27.01-51.228 32.644-3 1.03-5.325 1.857-7.153 2.544-0.117 2.906-0.138 4.933-0.138 8.638z" />
    </SvgIcon>
  );
};

type Props = SvgIconProps & {
  phenom: Phenomenon;
};

const Icons: React.FC<Props> = ({ phenom, ...rest }) => {
  switch (phenom) {
    case 'MOD_TURB':
      return <ModTurbIcon {...rest} />;
    case 'SEV_TURB':
      return <SevTurbIcon {...rest} />;
    case 'MOD_ICING':
      return <ModIcingIcon {...rest} />;
    case 'SEV_ICING':
      return <SevIcingIcon {...rest} />;
    case 'WS':
      return <WindshearIcon {...rest} />;
    case 'SS':
      return <SandstormIcon {...rest} />;
    case 'TS':
      return <ThunderstormIcon {...rest} />;
    case 'CB':
      return <CumulonimbusIcon {...rest} />;
    case 'VA':
      return <VulcanoIcon {...rest} />;
    case 'VA_CLD':
      return <VulcanoCloudIcon {...rest} />;
    case 'TEMP_INV':
      return <TempInvIcon {...rest} />;
    case 'GR':
      return <HailIcon {...rest} />;
    default:
      return <DefaultIcon {...rest} />;
  }
};

export const WeatherIcons = React.memo(Icons);
