import { logApiError } from '../../utils/handleApiError';
import { Alert } from '../../utils/snackBarContext';
import { deleteAirep } from '../fetchActions';

export const handleDelete = async (
  id: string,
  refetchAireps: () => void,
  addAlert?: (alert: Alert) => void,
) => {
  try {
    const response = await deleteAirep(id);
    if (!response)
      throw new Error('trying to delete Airep ended up with no response');
  } catch (error) {
    logApiError(error);
    addAlert && addAlert({ type: 'error', message: `deletion failed` });
  } finally {
    refetchAireps();
  }
};
