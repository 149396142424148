import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import AirplaneIcon from '@material-ui/icons/AirplanemodeActive';
import clsx from 'clsx';
import React from 'react';
import { Marker } from 'react-map-gl';
import { Flight } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      // transformOrigin: "center",
      '&:hover': {
        border: 'solid',
        borderColor: grey[50],
        borderRadius: '15px',
        borderWidth: '1px',
        color: grey[50],
      },
    },
    lowLevel: {
      color: grey[600],
    },
    highLevel: {
      color: grey[300],
    },
  }),
);

type Props = {
  flightList: Flight[];
  lastUpdate: number | undefined;
  handleClick: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    flight: Flight,
  ) => void;
};

export const FlightLayer: React.FC<Props> = ({ flightList, handleClick }) => {
  const classes = useStyles();
  return (
    <>
      {flightList.map((flight) => (
        <Marker
          key={`aircraft-icon-${flight.id}`}
          latitude={flight.lat}
          longitude={flight.long}
          // offset={[-12, -12]}
          anchor="center"
        >
          <AirplaneIcon
            className={clsx(
              classes.icon,
              flight.fl < 145 && classes.lowLevel,
              flight.fl >= 145 && classes.highLevel,
            )}
            style={{
              transform: `rotate(${flight.track}deg)`,
            }}
            onClick={(event) => {
              handleClick(event, flight);
            }}
          />
        </Marker>
      ))}
      {flightList.map((flight) => (
        <Marker
          key={`aircraft-callsign-${flight.id}`}
          latitude={flight.lat}
          longitude={flight.long}
          offset={[12, -6]}
          anchor="bottom-left"
        >
          <Typography variant="caption">{flight.callsign}</Typography>
        </Marker>
      ))}
      {flightList.map((flight) => (
        <Marker
          key={`aircraft-altitude-${flight.id}`}
          latitude={flight.lat}
          longitude={flight.long}
          offset={[12, 6]}
          anchor="bottom-left"
        >
          <Typography variant="caption" color="textSecondary">
            {flight.fl < 60 && `${flight.alt} ft`}
            {flight.fl >= 60 && `FL ${flight.fl}`}
          </Typography>
        </Marker>
      ))}
    </>
  );
};

// function areEqual(prevProps: Props, nextProps: Props) {
//   return (
//     prevProps.lastUpdate === nextProps.lastUpdate &&
//     prevProps.zoom === nextProps.zoom
//   );
// }

// export const FlightLayer = React.memo(Layer, areEqual);
