import axios from 'axios';
import { getApiBaseURL } from '../../constants/baseURL';

const baseURL = getApiBaseURL();

export async function deleteAirep(id: string) {
  const deleteURL = new URL(`/aireps/${id}`, baseURL);
  return await axios.delete(deleteURL.toString(), {
    headers: {
      Accept: 'application/json',
    },
  });
}
