import {
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {
  ModIcingIcon,
  ModTurbIcon,
  SevIcingIcon,
  SevTurbIcon,
  WindshearIcon,
} from '../../shared/WeatherIcons';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: 8,
    },
  }),
);

type SelectAirepMenuProps = {
  anchorFlight: (EventTarget & SVGSVGElement) | null;
  handleCloseFlightMenu: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => Promise<void>;
};
export const SelectAirepMenu: React.FunctionComponent<SelectAirepMenuProps> = ({
  anchorFlight,
  handleCloseFlightMenu,
}) => {
  const classes = useStyles();
  return (
    <Menu
      id="create-airep-menu"
      anchorEl={anchorFlight}
      keepMounted
      open={Boolean(anchorFlight)}
      onClose={handleCloseFlightMenu}
    >
      <MenuItem onClick={handleCloseFlightMenu} value="MOD_TURB">
        <ModTurbIcon className={classes.icon} />
        <Typography>mod turb</Typography>
      </MenuItem>
      <MenuItem onClick={handleCloseFlightMenu} value="SEV_TURB">
        <SevTurbIcon className={classes.icon} />
        <Typography>sev turb</Typography>
      </MenuItem>
      <MenuItem onClick={handleCloseFlightMenu} value="MOD_ICING">
        <ModIcingIcon className={classes.icon} />
        <Typography>mod icing</Typography>
      </MenuItem>
      <MenuItem onClick={handleCloseFlightMenu} value="SEV_ICING">
        <SevIcingIcon className={classes.icon} />
        <Typography>sev icing</Typography>
      </MenuItem>
      <MenuItem onClick={handleCloseFlightMenu} value="WS">
        <WindshearIcon className={classes.icon} />
        <Typography>windshear</Typography>
      </MenuItem>
    </Menu>
  );
};
