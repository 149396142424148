import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { formatDistanceToNow } from 'date-fns';
import { last } from 'ramda';
import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { readablePhenomenon } from '../../../shared/phenomDescription';
import {
  CumulonimbusIcon,
  HailIcon,
  ModIcingIcon,
  ModTurbIcon,
  SandstormIcon,
  SevIcingIcon,
  SevTurbIcon,
  TempInvIcon,
  ThunderstormIcon,
  VulcanoCloudIcon,
  VulcanoIcon,
  WindshearIcon,
} from '../../../shared/WeatherIcons';
import {
  AirepFull,
  hasAltitude,
  hasFlightLevel,
} from '../../../types/airepTypes';

type Props = {
  airepList: AirepFull[];
  handleClick: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    airep: AirepFull,
  ) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popupTitle: {
      // fontWeight: "bold",
      color: grey[500],
    },
    popupBody: {
      // fontWeight: "bold",
      color: grey[800],
      // textAlign:"right"
    },
    icon: {
      opacity: 0,
    },
  }),
);

const Layer: React.FC<Props> = ({ airepList, handleClick }) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleMouseOver = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    id: string,
  ) => {
    setSelectedId(id);
  };

  return (
    <>
      {airepList.map((airep) => (
        <Marker
          key={`airep-${airep._id}`}
          latitude={airep.location.coordinates[1]}
          longitude={airep.location.coordinates[0]}
          offset={[-7, -7]}
        >
          <WeatherIcons
            airep={airep}
            onClick={(event) => {
              handleClick(event, airep);
            }}
            onMouseOver={(event) => handleMouseOver(event, airep._id)}
            onMouseLeave={() => setSelectedId(null)}
          />
        </Marker>
      ))}
      {airepList.map((airep) =>
        airep._id === selectedId ? (
          <Popup
            key={`popup-${airep._id}`}
            latitude={airep.location.coordinates[1]}
            longitude={airep.location.coordinates[0]}
            closeButton={false}
            offset={[0, -10]}
          >
            <Typography className={classes.popupTitle}>
              {readablePhenomenon(airep.phenomenon)}
            </Typography>
            <Typography className={classes.popupBody}>
              {`- by ${airep.aircraft_callsign}`}
              {airep.aircraft_type !== 'ZZZZ'
                ? ` (${airep.aircraft_type})`
                : null}
            </Typography>
            <Typography className={classes.popupBody}>
              {hasFlightLevel(airep.flightLevelOrAltitude) &&
                `- at FL${airep.flightLevelOrAltitude.flightLevel.flightLevel_1}`}
              {hasAltitude(airep.flightLevelOrAltitude) &&
                `- at ${airep.flightLevelOrAltitude.altitude.altitude_1}
                    ${airep.flightLevelOrAltitude.altitude.unit.toLowerCase()}`}
            </Typography>
            <Typography className={classes.popupBody}>
              {`- ${formatDistanceToNow(new Date(airep.created))} ago`}
            </Typography>
          </Popup>
        ) : null,
      )}
    </>
  );
};

type WeatherIconProps = SvgIconProps & { airep: AirepFull };

const WeatherIcons: React.FC<WeatherIconProps> = ({ airep, ...rest }) => {
  const classes = useStyles();
  const props = { className: classes.icon, ...rest };

  switch (airep.phenomenon) {
    case 'MOD_TURB':
      return <ModTurbIcon {...props} />;
    case 'SEV_TURB':
      return <SevTurbIcon {...props} />;
    case 'MOD_ICING':
      return <ModIcingIcon {...props} />;
    case 'SEV_ICING':
      return <SevIcingIcon {...props} />;
    case 'WS':
      return <WindshearIcon {...props} />;
    case 'SS':
      return <SandstormIcon {...props} />;
    case 'TS':
      return <ThunderstormIcon {...props} />;
    case 'CB':
      return <CumulonimbusIcon {...props} />;
    case 'VA':
      return <VulcanoIcon {...props} />;
    case 'VA_CLD':
      return <VulcanoCloudIcon {...props} />;
    case 'TEMP_INV':
      return <TempInvIcon {...props} />;
    case 'GR':
      return <HailIcon {...props} />;

    default:
      return null;
  }
};

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    last(prevProps.airepList)?.updated === last(nextProps.airepList)?.updated
  );
}

export const PopupLayer = React.memo(Layer, areEqual);
