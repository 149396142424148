import React from 'react';
import { Layer, Source } from 'react-map-gl';
import lfbbRoutes from '../../../assets/routes-LFBB.json';
import lfeeRoutes from '../../../assets/routes-LFEE.json';
import lfffRoutes from '../../../assets/routes-LFFF.json';
import lfmmRoutes from '../../../assets/routes-LFMM.json';
import lfrrRoutes from '../../../assets/routes-LFRR.json';
import { useAuth } from '../../../UserContext/UserContext';

const layerStyle = {
  id: 'line',
  type: 'line',
  paint: {
    'line-color': '#808080',
  },
} as const;

type Props = {};

const MyLayer: React.FC<Props> = () => {
  const { myUser } = useAuth();

  if (!myUser) return null;

  const geojson = (() => {
    switch (myUser.center) {
      case 'LFBB':
        return lfbbRoutes;
      case 'LFRR':
        return lfrrRoutes;
      case 'LFFF':
        return lfffRoutes;
      case 'LFEE':
        return lfeeRoutes;
      case 'LFMM':
        return lfmmRoutes;
      default:
        return null;
    }
  })();
  if (!geojson) return null;
  return (
    <Source id="my-data" type="geojson" data={geojson as any}>
      <Layer {...layerStyle} />
    </Source>
  );
};

export const BackgroundLayer = React.memo(MyLayer);
