import { add, compareAsc } from 'date-fns';
import { EXPIRE_DURATION } from '../constants';
import { AirepFull } from '../types/airepTypes';
import { timeIsEarlierThan } from './timeUtilities';

export const getActiveAireps = (
  airepList: AirepFull[] | null,
): AirepFull[] | null => {
  if (!airepList) return null;
  return airepList
    .filter((airep) => !airep.cancelled)
    .filter((airep) =>
      timeIsEarlierThan(add(new Date(airep.reported_time), EXPIRE_DURATION)),
    )
    .sort((airep1, airep2) =>
      compareAsc(new Date(airep1.updated), new Date(airep2.updated)),
    );
};
