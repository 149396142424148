import { createTheme, responsiveFontSizes } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blue[400],
    },
    secondary: {
      main: cyan[400],
    },
  },
});

export const darkTheme = responsiveFontSizes(theme, {
  breakpoints: ['xs', 'sm', 'md', 'lg'],
  factor: 20,
});
