import { isError } from 'lodash';

export const logApiError = (error: any) => {
  if (error.response) {
    console.error('error data : ', error.response.data);
    console.error('error status : ', error.response.status);
    console.error('error headers : ', error.response.headers);
  } else if (error.request) {
    console.error('error request : ', error.request);
  } else if (typeof error === 'string') {
    console.error('error message : ', error);
  } else if (isError(error)) {
    console.error(`Error message : ${error.name} -  ${error.message}`);
  }
  console.error('config : ', error.config);
};
