import { add } from 'date-fns';
import { EXPIRE_DURATION } from '../constants';
import { AirepFull } from '../types/airepTypes';
import { timeIsLaterThan } from '../utils/timeUtilities';

type Status = 'ACTIVE' | 'CANCELLED' | 'EXPIRED';

export const getAirepStatus = (airep: AirepFull): Status => {
  if (airep.cancelled) {
    return 'CANCELLED';
  }
  if (timeIsLaterThan(add(new Date(airep.reported_time), EXPIRE_DURATION))) {
    return 'EXPIRED';
  }
  return 'ACTIVE';
};
