import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { isError } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import toast, { Toast } from 'react-hot-toast';
import ReactMapGL, { MapRef, ViewState } from 'react-map-gl';
import { handleRemove } from '../../apiActions/airepHandlers';
import { postAirep, useFetch } from '../../apiActions/fetchActions';
import { getApiBaseURL } from '../../constants/baseURL';
import { AirepFull, Phenomenon } from '../../types/airepTypes';
import { useAuth } from '../../UserContext/UserContext';
import { logApiError } from '../../utils/handleApiError';
import { SnackBarContext } from '../../utils/snackBarContext';
import { useInterval } from '../../utils/useInterval';
import { getInitialViewPort } from './getInitialViewPort';
import { AirepLayer } from './layers/AirepLayer';
import { BackgroundLayer } from './layers/BackgroundLayer';
import { FlightLayer } from './layers/FlightLayer';
import { PopupLayer } from './layers/PopupLayer';
import { SelectAirepMenu } from './SelectAirepMenu';
import { APIResponse, Flight } from './types';
import { buildHotCreatePayload, formatFlightList } from './utils';

const baseURL = getApiBaseURL();
// const PATH_OPENSKY = '/adsb/opensky';
const PATH_FR24 = '/adsb/fr24';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const MAPBOX_MAP_URL = 'mapbox://styles/mapbox/dark-v10';

const DEFAULT_FREQUENCY = 30 * 1000;

export type PartialViewState = Pick<
  ViewState,
  'latitude' | 'longitude' | 'zoom'
>;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

type Props = {
  refetch: () => void;
  airepList: AirepFull[];
  selectedAirep: AirepFull | null;
  setSelectedAirep: React.Dispatch<React.SetStateAction<AirepFull | null>>;
};

export const AirepMap: React.FC<Props> = ({
  refetch: refetchAireps,
  airepList,
  selectedAirep,
  setSelectedAirep,
}) => {
  const { myUser } = useAuth();

  const mapRef = useRef<MapRef>(null);

  const classes = useStyles();

  const {
    data,
    errorMessage,
    refetch: refetchAdsb,
    refetchIndex,
  } = useFetch<APIResponse>({
    initialBaseURL: baseURL,
    initialPath: PATH_FR24,
  });

  const { addAlert } = useContext(SnackBarContext);

  useEffect(() => {
    if (errorMessage) {
      addAlert({ type: 'error', message: errorMessage });
    }
  }, [addAlert, errorMessage, refetchIndex]);

  const [anchorFlight, setAnchorFlight] = useState<
    (EventTarget & SVGSVGElement) | null
  >(null);

  useEffect(() => {
    if (selectedAirep) {
      mapRef.current?.flyTo({
        center: [
          selectedAirep.location.coordinates[0] + 0.4,
          selectedAirep.location.coordinates[1],
        ],
        duration: 2000,
      });
    }
  }, [selectedAirep]);

  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);

  const handleClickFlight = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>, flight: Flight) => {
      if (myUser?.canPost()) {
        setSelectedFlight(flight);
        setAnchorFlight(event.currentTarget);
      }
    },
    [myUser],
  );

  const handleClickAirep = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>, airep: AirepFull) => {
      setSelectedAirep(airep);
    },
    [setSelectedAirep],
  );

  const handleRemoveAirep = useCallback(
    (t: Toast, createdAirep: AirepFull) => async () => {
      await handleRemove(createdAirep, refetchAireps, addAlert);
      toast.dismiss(t.id);
    },
    [addAlert, refetchAireps],
  );

  const handleCloseFlightMenu = useCallback(
    async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const phenom = event.currentTarget.getAttribute(
        'value',
      ) as Phenomenon | null;
      if (selectedFlight && phenom) {
        const payload = buildHotCreatePayload(selectedFlight, phenom);
        try {
          const { data: createdAirep } = await postAirep(payload);
          if (!createdAirep) {
            throw new Error('failed to create Airep');
          }
          toast(
            (t) => (
              <span>
                Airep created for {` `}
                <b>{selectedFlight.callsign}</b>
                <Button
                  className={classes.button}
                  size="small"
                  onClick={handleRemoveAirep(t, createdAirep)}
                  color="primary"
                >
                  undo
                </Button>
              </span>
            ),
            {
              icon: <CheckCircleOutlineIcon style={{ color: green[500] }} />,
            },
          );
        } catch (error) {
          logApiError(error);
          if (isError(error)) {
            addAlert({
              type: 'error',
              message: 'failed to post Airep' + error.message,
            });
          }
        } finally {
          refetchAireps();
        }
      }

      setSelectedFlight(null);
      setAnchorFlight(null);
    },
    [
      addAlert,
      classes.button,
      handleRemoveAirep,
      refetchAireps,
      selectedFlight,
    ],
  );

  useInterval(() => {
    refetchAdsb();
    refetchAireps();
  }, DEFAULT_FREQUENCY);

  const lastUpdate = data?.time;
  const flightList = formatFlightList(data).filter(({ fl }) => fl >= 145);

  return (
    <>
      <ReactMapGL
        ref={mapRef}
        pitchWithRotate={false}
        dragRotate={false}
        initialViewState={getInitialViewPort(myUser)}
        maxZoom={20}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={MAPBOX_MAP_URL}
      >
        <BackgroundLayer />
        <AirepLayer airepList={airepList} selectedAirep={selectedAirep} />
        <FlightLayer
          flightList={flightList}
          lastUpdate={lastUpdate}
          handleClick={handleClickFlight}
        />
        <PopupLayer airepList={airepList} handleClick={handleClickAirep} />
      </ReactMapGL>
      <SelectAirepMenu
        anchorFlight={anchorFlight}
        handleCloseFlightMenu={handleCloseFlightMenu}
      />
    </>
  );
};
