import axios, { AxiosResponse } from 'axios';
import { getApiBaseURL } from '../../constants/baseURL';
import { Airep, AirepFull } from '../../types/airepTypes';

const baseURL = getApiBaseURL();
const airepURL = new URL('/aireps', baseURL);

export async function postAirep(payload: Airep) {
  return await axios.post<Airep, AxiosResponse<AirepFull>>(
    airepURL.toString(),
    payload,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
}
