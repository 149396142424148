import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../UserContext/UserContext';
import { getLatestApiInfos } from '../../utils/getLatestApiInfos';
import { getLatestClientInfos } from '../../utils/getLatestClientInfos';
import { Spacer } from '../../utils/Spacer';
import { useInterval } from '../../utils/useInterval';
import { Clock } from '../Clock/Clock';

export const url = '/status';

export const TopBar: React.FC = () => {
  const { myUser, setMyUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [versions, setVersions] = useState<{
    client: string | null;
    api: string | null;
  } | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const action = event.currentTarget.getAttribute('value') as string | null;
    if (action === 'LOGOUT') {
      setMyUser(null);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log('fetching versions (first time)...');
    async function getVersions() {
      const [{ version: client }, { version: api }] = await Promise.all([
        getLatestClientInfos(),
        getLatestApiInfos(),
      ]);
      setVersions({ client, api });
    }
    getVersions();
  }, []);

  useInterval(async () => {
    console.log('fetching versions ...');
    async function getVersions() {
      const [{ version: client }, { version: api }] = await Promise.all([
        getLatestClientInfos(),
        getLatestApiInfos(),
      ]);
      setVersions({ client, api });
    }
    getVersions();
  }, 5 * 60 * 1000);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4">Web Client - AIREP SPECIAUX</Typography>
          <Spacer x={2} />
          <Clock />
          <Box flexGrow={1} marginLeft={4}>
            <Typography variant="h4" color="textPrimary" align="center">
              DSNA - DTI - METEO FRANCE
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" style={{ color: green[200] }}>
              {`CLIENT : ${versions?.client ?? '?'}`}
            </Typography>
            <Divider />
            <Typography variant="body2" style={{ color: green[200] }}>
              {`API : ${versions?.api ?? '?'}`}
            </Typography>
          </Box>
          <Spacer x={2} />
          {myUser?.username && (
            <Box marginRight={4}>
              <Button color="inherit" onClick={handleClick}>
                <Typography>{myUser.username}</Typography>
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem value="LOGOUT" onClick={handleClose}>
          logout
        </MenuItem>
      </Menu>
    </>
  );
};
