import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { App } from './App';
import { Login } from './Login';
import { PrivateRoute } from './routing/PrivateRoute';

export const Main = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />;
      </Route>
      <PrivateRoute path="/">
        <App />
      </PrivateRoute>
    </Switch>
  );
};
