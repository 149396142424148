import axios from 'axios';
import { logApiError } from './handleApiError';

type ClientInfos = {
  version: string | null;
  sha: string | null;
  environment: string | null;
};

export async function getLatestClientInfos(): Promise<ClientInfos> {
  if (process.env.NODE_ENV === 'development')
    return {
      version: 'dev',
      sha: null,
      environment: 'dev',
    };
  try {
    const response = await axios.get<ClientInfos>('version.json');
    if (!response || !response.data)
      throw new Error('no response while querying version.json');
    console.log('version.json = ', response.data);
    return response.data;
  } catch (err) {
    logApiError(err);
    console.error('Error fetching client version (version.json)');
    return {
      version: null,
      sha: null,
      environment: null,
    };
  }
}
