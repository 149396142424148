import axios, { AxiosResponse } from 'axios';
import { getApiBaseURL } from './constants/baseURL';

const baseURL = getApiBaseURL();
const loginURL = new URL('/login', baseURL);

type Props = {
  username: string;
  password: string;
};

export const getToken = async ({
  username,
  password,
}: Props): Promise<AxiosResponse<string>> => {
  const payload = {
    username,
    password,
  };
  return await axios.post(loginURL.toString(), payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
