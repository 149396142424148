import { Phenomenon } from '../types/airepTypes';

const PHENOM_DESCRIPTION = {
  MOD_TURB: 'MODERATE TURBULENCE',
  SEV_TURB: 'SEVERE TURBULENCE',
  MOD_ICING: 'MODERATE ICING',
  SEV_ICING: 'SEVERE ICING',
  SEV_MTW: 'SEVERE MONTAIN WAVE',
  TS: 'THUNDERSTORM',
  TSGR: 'THUNDERSTORM + HAIL',
  SS: 'SAND STORM',
  VA: 'VULCANO ACTIVITY',
  VA_CLD: 'VULCANO CLOUDS',
  GR: 'HAIL',
  CB: 'CUMULONIMBUS',
  WS: 'WINDSHEAR',
  TEMP_INV: 'TEMPERATURE INVERSION',
};

export const readablePhenomenon = (phenom: Phenomenon): string =>
  PHENOM_DESCRIPTION[phenom];
