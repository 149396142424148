import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type MessageType = 'error' | 'warning' | 'info' | 'success';

export type Alert = {
  type: MessageType;
  message: string;
};

const AUTO_HIDE_DURATION = 3000;

export const SnackBarContext = createContext({
  addAlert: (alert: Alert) => {},
});

function AlertComponent(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SnackBarProvider({ children }: { children: React.ReactNode }) {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [open, setOpen] = useState(false);
  const [currentAlert, setCurrentAlert] = useState<Alert | null>(null);

  useEffect(() => {
    if (currentAlert === null && alerts.length !== 0) {
      setCurrentAlert(alerts[0]);
      setAlerts((prev) => prev.slice(1));
      setOpen(true);
    }
  }, [currentAlert, alerts]);

  const handleExited = useCallback(() => {
    setCurrentAlert(null);
  }, []);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    [],
  );

  const addAlert = useCallback((alert: Alert) => {
    setAlerts((alerts) => [...alerts, alert]);
  }, []);

  const value = useMemo(() => ({ addAlert }), [addAlert]);

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <AlertComponent onClose={handleClose} severity={currentAlert?.type}>
          {currentAlert?.message}
        </AlertComponent>
      </Snackbar>
    </SnackBarContext.Provider>
  );
}
