import { MyUser } from '../../UserContext/myUser';
import { PartialViewState } from './Map';

export function getInitialViewPort(myUser: MyUser | null): PartialViewState {
  return (myUser && viewPortDataset[myUser.center]) ?? viewPortDataset.DEFAULT;
}

const viewPortDataset: {
  [key: string]: PartialViewState;
} = {
  LFBB: {
    latitude: 45.0,
    longitude: 2,
    zoom: 6,
  },
  LFRR: {
    latitude: 48.0,
    longitude: -1.7,
    zoom: 6,
  },
  LFFF: {
    latitude: 48.9,
    longitude: 2.3,
    zoom: 6,
  },
  LFEE: {
    latitude: 49.2,
    longitude: 4,
    zoom: 6,
  },
  LFMM: {
    latitude: 45.0,
    longitude: 4.9,
    zoom: 6,
  },
  METEO: {
    latitude: 47.0,
    longitude: 2.4,
    zoom: 4,
  },
  DEFAULT: {
    latitude: 47.0,
    longitude: 2.4,
    zoom: 4,
  },
} as const;
