import axios from 'axios';
import { getApiBaseURL } from '../../constants/baseURL';
import { Airep } from '../../types/airepTypes';

const baseURL = getApiBaseURL();
const airepURL = new URL('/aireps', baseURL);

type Payload = Partial<Airep> & { _id: string };

export async function updateAirep(payload: Payload) {
  return await axios.put(airepURL.toString(), payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}
