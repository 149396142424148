export interface AuthorType {
  id: string;
  name: string;
  type: 'CWP' | 'FLOWMANAGER' | 'SUPERVISOR' | 'TECHNICALSUPERVISOR' | 'OTHER';
  center: string;
}

export interface Emitter {
  id: string;
  name: string;
}

export interface PointType {
  type: 'Point';
  coordinates: [number, number];
}

export interface PointReferenceType {
  significant_point: string;
  distance: number;
  bearing: number;
  abeam: boolean;
}

export type Phenomenon =
  | 'MOD_TURB'
  | 'SEV_TURB'
  | 'MOD_ICING'
  | 'SEV_ICING'
  | 'SEV_MTW'
  | 'TS'
  | 'TSGR'
  | 'SS'
  | 'VA'
  | 'VA_CLD'
  | 'GR'
  | 'CB'
  | 'WS'
  | 'TEMP_INV';

interface FlightLevelType {
  flightLevel: {
    flightLevel_1: number;
    flightLevel_2?: number;
    tendancy?: 'ASC' | 'DES';
  };
}

interface AltitudeType {
  altitude: {
    altitude_1: number;
    altitude_2?: number;
    unit: 'FT' | 'M';
    tendancy?: 'ASC' | 'DES';
  };
}
export interface Airep {
  emitter?: Emitter;
  addressee_identifier: string[];
  reported_time: Date;
  expire?: Date;
  cancelled?: Date;
  aircraft_callsign: string;
  aircraft_icao24?: string;
  aircraft_type?: string;
  location: PointType;
  point_reference?: PointReferenceType;
  phenomenon: Phenomenon;
  flightLevelOrAltitude: FlightLevelType | AltitudeType;
  remark?: string;
}
// export interface AirepWithId extends Airep {
//   _id: string;
// }

export interface AirepFull extends Airep {
  _id: string;
  created: Date;
  updated: Date;
  author: AuthorType;
}

export function hasFlightLevel(
  flightLevelOrAltitude: FlightLevelType | AltitudeType,
): flightLevelOrAltitude is FlightLevelType {
  return (flightLevelOrAltitude as FlightLevelType).flightLevel !== undefined;
}

export function hasAltitude(
  flightLevelOrAltitude: FlightLevelType | AltitudeType,
): flightLevelOrAltitude is AltitudeType {
  return (flightLevelOrAltitude as AltitudeType).altitude !== undefined;
}
