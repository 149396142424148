import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import InactiveIcon from '@material-ui/icons/Remove';
import { add, compareDesc } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { EXPIRE_DURATION } from '../../constants';
import { getAirepStatus } from '../../shared/getAirepStatus';
import { WeatherIcons } from '../../shared/WeatherIcons';
import { AirepFull, hasAltitude, hasFlightLevel } from '../../types/airepTypes';
import { SnackBarContext } from '../../utils/snackBarContext';
import { timeIsEarlierThan } from '../../utils/timeUtilities';
import { timeAgo } from './utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

type Props = {
  airepList: AirepFull[];
  refetch: () => void;
  setSelectedAirep: React.Dispatch<React.SetStateAction<AirepFull | null>>;
};

export const AirepList: React.FC<Props> = ({
  airepList,
  refetch,
  setSelectedAirep,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { addAlert } = useContext(SnackBarContext);
  const [time, setTime] = useState(new Date());

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowCLick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    airep: AirepFull,
  ) => {
    setSelectedAirep(airep);
  };

  useEffect(() => {
    const timerId = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <Box marginTop={2}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key="aircrat_id">CALLSIGN</TableCell>
                <TableCell key="age">AGE</TableCell>
                <TableCell key="phenomenon">PHEN</TableCell>
                <TableCell key="flightLevel_1">FL</TableCell>
                <TableCell key="state">STATE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {airepList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((a, b) =>
                  compareDesc(new Date(a.created), new Date(b.created)),
                )
                .map((airep) => {
                  const {
                    _id,
                    created,
                    expire,
                    cancelled,
                    reported_time,
                    aircraft_callsign,
                    aircraft_type,
                    phenomenon,
                    flightLevelOrAltitude,
                  } = airep;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={_id}
                      onClick={(event) => handleRowCLick(event, airep)}
                    >
                      <TableCell key="aircraft_callsign">
                        {aircraft_callsign}
                      </TableCell>
                      <TableCell key="age">
                        {(() => {
                          switch (getAirepStatus(airep)) {
                            case 'ACTIVE':
                              return (
                                <Typography color="textPrimary">
                                  {timeAgo(new Date(created))}
                                </Typography>
                              );
                            case 'EXPIRED':
                              return (
                                <Typography
                                  color="textSecondary"
                                  variant="body1"
                                >
                                  EXPIRED
                                </Typography>
                              );
                            case 'CANCELLED':
                              return (
                                <Typography
                                  color="textSecondary"
                                  variant="body1"
                                >
                                  CANCELLED
                                </Typography>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </TableCell>
                      <TableCell key="phenomenon">
                        <WeatherIcons phenom={phenomenon} />
                      </TableCell>
                      <TableCell key="flightLevel_1">
                        {hasFlightLevel(flightLevelOrAltitude) &&
                          'FL' +
                            flightLevelOrAltitude.flightLevel.flightLevel_1}
                        {hasAltitude(flightLevelOrAltitude) &&
                          flightLevelOrAltitude.altitude.altitude_1 +
                            flightLevelOrAltitude.altitude.unit.toLowerCase()}
                      </TableCell>
                      <TableCell key="state">
                        {timeIsEarlierThan(
                          add(new Date(reported_time), EXPIRE_DURATION),
                        ) ? (
                          <CheckIcon style={{ color: green[500] }} />
                        ) : (
                          <InactiveIcon style={{ color: grey[500] }} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  count={airepList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <IconButton aria-label="refresh" onClick={refetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
