import { intervalToDuration } from 'date-fns';

export const timeAgo = (date: Date): string => {
  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start: date,
    end: Date.now(),
  });
  if (years || months || days || hours) return `> 1 h`;
  if (minutes && minutes > 0) return `${minutes} min`;
  return `< 1 min`;
};
