import { add } from 'date-fns';
import { EXPIRE_DURATION } from '../../constants';
import { Airep, Phenomenon } from '../../types/airepTypes';
import { APIFlight, APIResponse, Flight } from './types';

export function formatFlightList(rawFlightList: APIResponse | null): Flight[] {
  if (
    !rawFlightList ||
    !rawFlightList.states ||
    !Array.isArray(rawFlightList.states)
  )
    return [];

  return (rawFlightList as APIResponse).states
    .map((flight) => transformFlight(flight))
    .filter(Boolean) as Flight[];
}

export function transformFlight(raw: APIFlight): null | Flight {
  const icaoAddr = raw[0];
  const callsign = raw[1];
  const when = raw[3];
  const long = raw[5];
  const lat = raw[6];
  const baroAltitude = raw[7];
  const groundSpeed = raw[9];
  const track = raw[10];
  const verticalSpeed = raw[11];
  const geoAltitude = raw[13];
  const squawk = raw[14];
  const type = raw[17];

  if (
    callsign === null ||
    !callsign.trim() ||
    long === null ||
    lat === null ||
    groundSpeed === null ||
    track === null ||
    when === null ||
    baroAltitude === null ||
    geoAltitude === null
  ) {
    return null;
  }

  return {
    id: icaoAddr,
    icaoAddr,
    callsign: callsign.trim(),
    lat,
    long,
    fl: meterToFl(baroAltitude),
    alt: meterToFeet(geoAltitude),
    track,
    groundSpeed: msToKnots(groundSpeed),
    squawk: squawk ?? 'ZZZZ',
    type: type ?? 'ZZZZ',
    departure: 'ZZZZ',
    destination: 'ZZZZ',
    verticalSpeed: meterToFeet(60 * (verticalSpeed || 0)), // Convert m/s to feet/min
    when: new Date(when * 1000),
  };
}

const FEET = 3.28084;
const KNOT = 1.94384;

export function meterToFl(m: number): number {
  return Math.round((m * FEET) / 100);
}

export function meterToFeet(m: number): number {
  return Math.round(m * FEET);
}

export function msToKnots(m: number): number {
  return Math.round(m * KNOT);
}

export const buildHotCreatePayload = (
  selectedFlight: Flight,
  phenom: Phenomenon,
): Airep => ({
  addressee_identifier: ['MeteoFrance', '4me'],
  reported_time: new Date(),
  expire: add(new Date(), EXPIRE_DURATION),
  aircraft_callsign: selectedFlight.callsign,
  ...(selectedFlight.icaoAddr && { aircraft_icao24: selectedFlight.icaoAddr }),
  aircraft_type: selectedFlight.type ?? 'ZZZZ',
  location: {
    type: 'Point',
    coordinates: [selectedFlight.long, selectedFlight.lat],
  },
  phenomenon: phenom,
  flightLevelOrAltitude: {
    ...(selectedFlight.fl < 60
      ? {
          altitude: {
            altitude_1: selectedFlight.alt,
            altitude_2: undefined,
            unit: 'FT',
          },
        }
      : {
          flightLevel: {
            flightLevel_1: selectedFlight.fl,
            flightLevel_2: undefined,
          },
        }),
  },
  remark: 'implemented via web-airep',
});
