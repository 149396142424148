import * as React from 'react';

export function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 55.63 24.3" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-1{fill:#d48404}.prefix__cls-2{fill:#ffffff}.prefix__cls-3{fill:#d48404}'
          }
        </style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            className="prefix__cls-1"
            d="M31.82 18.65h-12.5l-5.51-9.86a2.09 2.09 0 00-3.62 0l-5.51 9.86h-2.5A2.17 2.17 0 000 20.81v.82a2.16 2.16 0 002.18 2.16h4.35l3.66-6.54a2.09 2.09 0 013.62 0l3.66 6.54h14.35A2.16 2.16 0 0034 21.63v-.82a2.17 2.17 0 00-2.18-2.16z"
          />
          <path
            className="prefix__cls-1"
            d="M19.67 6.85L13.39.57a2 2 0 00-2.78 0L4.33 6.85a1.94 1.94 0 002.74 2.74l3.18-3.18A2.78 2.78 0 0112 5.88a2.78 2.78 0 011.75.53l3.18 3.18a1.94 1.94 0 002.74-2.74z"
          />
          <path
            className="prefix__cls-2"
            d="M22.77 14.67v2.34c0 .08 0 .12-.14.14a7.2 7.2 0 01-.86 0 7.32 7.32 0 01-.86 0c-.08 0-.13 0-.15-.07a.58.58 0 010-.17V15.8v-1.13a19.6 19.6 0 00-.12-2.44.54.54 0 010-.11c0-.07.05-.11.15-.11a3.74 3.74 0 01.48 0h1.46c.09 0 .14 0 .14.11a.84.84 0 010 .1 22 22 0 00-.1 2.45zm.15-4c0 .62-.37.93-1.1.93a1.32 1.32 0 01-.79-.23.86.86 0 01-.33-.7.82.82 0 01.35-.69 1.19 1.19 0 01.77-.25 1.15 1.15 0 01.75.25.84.84 0 01.35.69zM27.58 13.79q0 .21-.24.21h-.65a1 1 0 00-.63.14c-.12.09-.17.28-.17.57v1.4a4.57 4.57 0 000 .47v.46c0 .1-.06.14-.18.15h-1.69c-.22 0-.18-.08-.18-.18v-1.33-1.29a7.6 7.6 0 00-.24-2.09.25.25 0 010-.08.11.11 0 01.08-.11l.91-.12.91-.16c.07 0 .11.11.13.33s.05.34.1.34.1-.09.46-.28a2.23 2.23 0 011-.28c.21 0 .32 0 .32.13a2.93 2.93 0 010 .44v.86c0 .18.07.35.07.42zM33.39 14.22c0 .36-.12.57-.37.62l-1.16.16c-.44 0-1.1.1-2 .16a.76.76 0 00.48.54 1.75 1.75 0 00.74.15 2.26 2.26 0 001-.26l.42-.26a1.7 1.7 0 01.4.55 1.88 1.88 0 01.28.63s0 .07-.09.13a2.83 2.83 0 01-2.13.7 3 3 0 01-2.15-.75 2.56 2.56 0 01-.85-2 2.79 2.79 0 01.76-2 2.67 2.67 0 012.05-.82 2.53 2.53 0 011.84.75 2.49 2.49 0 01.78 1.7zm-2-.33a.81.81 0 00-.25-.59.78.78 0 00-.59-.25.71.71 0 00-.58.28 1 1 0 00-.21.62.13.13 0 00.15.15 10.75 10.75 0 001.3-.12q.21.02.21-.09zM40.2 14.55a2.72 2.72 0 01-.7 1.92 2.52 2.52 0 01-1.92.78 2.37 2.37 0 01-.93-.2 2.15 2.15 0 01-.45-.21c-.05 0-.07.06-.07.17v1.65c0 .18-.07.17-.19.17h-1.09a2.53 2.53 0 01-.5 0 .19.19 0 01-.17-.2v-4.15a9.78 9.78 0 00-.2-2.12.28.28 0 010-.09.11.11 0 01.07-.11 5.1 5.1 0 01.83-.17 6.63 6.63 0 01.87-.09c.15 0 .23.09.25.28s.05.27.08.27.12-.09.48-.29a2 2 0 011-.29 2.55 2.55 0 012 .77 2.68 2.68 0 01.64 1.91zm-2.08 0a1.08 1.08 0 00-.28-.75.92.92 0 00-.73-.34.86.86 0 00-.72.34 1.16 1.16 0 00-.24.75 1.12 1.12 0 00.25.76.89.89 0 00.73.33c.66-.01.99-.37.99-1.1z"
          />
          <path
            className="prefix__cls-3"
            d="M41.05 21.69a1.36 1.36 0 00-.41-.37 2.9 2.9 0 00-.62-.27 4.1 4.1 0 00-.73-.15 6.92 6.92 0 01-2.08-.59.63.63 0 01-.18-.16.35.35 0 01-.09-.22 2.08 2.08 0 01.16-.8 1.54 1.54 0 01.42-.62 3.49 3.49 0 011.48-.66 9.3 9.3 0 012.4-.28 5.59 5.59 0 011.23.1c.29.07.44.17.44.31a1 1 0 01-.12.52.58.58 0 01-.24.25l-.46.16q-.35.11-.6.21a3.67 3.67 0 00-.44.21 1 1 0 01-.23.1s.06-.13.19-.23a1.75 1.75 0 01.49-.28c.2-.08.3-.14.3-.16s-.11-.06-.34-.06a11.14 11.14 0 00-1.89.15 5.43 5.43 0 00-1.37.39c-.33.16-.5.33-.5.5s.07.18.21.22.44.12.91.22a9.93 9.93 0 011.27.35 4.08 4.08 0 011.19.59.84.84 0 01.39.67 1 1 0 01-.15.52 1.83 1.83 0 01-.45.48 2.83 2.83 0 01-.67.35 6.45 6.45 0 01-.83.22 10.22 10.22 0 01-1.07.15 10.56 10.56 0 01-1.1.06 6.51 6.51 0 01-1.56-.15c-.39-.1-.59-.25-.59-.45a.93.93 0 01.2-.42 1 1 0 01.47-.39 2 2 0 01.46-.09h.22l-.18.06h-.11a.05.05 0 000 .06q0 .09 1.14.09c.67 0 1.21 0 1.64-.06a5.59 5.59 0 001.12-.21 3 3 0 00.68-.32z"
          />
          <path
            className="prefix__cls-3"
            d="M41.89 22v-.25c.33-.37.61-.71.85-1l.56-.75a1.85 1.85 0 01.37-.4.6.6 0 01.39-.11q.12 0 .12.09a.5.5 0 01-.09.22 2.21 2.21 0 01-.25.3 3 3 0 00-.39.5c-.13.21-.27.46-.44.78l.44-.36c.17-.14.3-.25.4-.32a2.16 2.16 0 01.31-.19.92.92 0 01.31-.07.18.18 0 01.13 0 .22.22 0 010 .14 1.12 1.12 0 01-.13.53 2.74 2.74 0 01-.37.54 6.75 6.75 0 01-.6.61.74.74 0 00.25 0 1.87 1.87 0 001.09-.64V22c-.17.16-.35.32-.52.46a3.08 3.08 0 01-.48.34.78.78 0 01-.41.13h-.3l-.13-.17-.16-.06h-.13a.63.63 0 01-.28-.1 5.06 5.06 0 00-.26.59c-.09.24-.17.44-.23.58a2.5 2.5 0 01-.25.41c-.06.06-.1.09-.13.09s-.15-.09-.15-.27a1.46 1.46 0 01.09-.49 3.17 3.17 0 01.23-.49c.09-.15.19-.31.3-.46l.23-.35a18.17 18.17 0 00.86-1.66c-.55.7-.95 1.18-1.23 1.45zm1.43.17a4.39 4.39 0 00.51-.63.78.78 0 00.17-.4c0-.09 0-.13-.13-.13a.93.93 0 00-.49.29 4.38 4.38 0 00-.65.8 1.55 1.55 0 01.34 0 1.11 1.11 0 01.25.09z"
          />
          <path
            className="prefix__cls-3"
            d="M45.41 21.85v.09a.54.54 0 00.1.33.37.37 0 00.27.11.78.78 0 00.36-.08 2.11 2.11 0 00.37-.22l.44-.36v.22a4.28 4.28 0 01-.74.74 1.16 1.16 0 01-.71.26.56.56 0 01-.49-.25 1 1 0 01-.19-.67 1.4 1.4 0 01.17-.68 2.7 2.7 0 01.57-.65 1.87 1.87 0 01.53-.35 1.13 1.13 0 01.47-.12.42.42 0 01.26.08.29.29 0 01.1.23.77.77 0 01-.17.45 1.85 1.85 0 01-.5.44 5.44 5.44 0 01-.84.43zm0-.24a1.78 1.78 0 00.59-.31 1.5 1.5 0 00.33-.35.66.66 0 00.11-.25c0-.06 0-.09-.08-.09a.45.45 0 00-.2.09 1.32 1.32 0 00-.29.21 1.58 1.58 0 00-.26.31.93.93 0 00-.16.39z"
          />
          <path
            className="prefix__cls-3"
            d="M49.07 21.72V22l-.62.58a2.68 2.68 0 01-.44.32.8.8 0 01-.4.12.54.54 0 01-.36-.16 1.1 1.1 0 01-.25-.41 1.6 1.6 0 01-.09-.53.89.89 0 01.1-.44 2.74 2.74 0 01.41-.49 3.78 3.78 0 01.67-.55 1 1 0 01.48-.2.27.27 0 01.21.09.32.32 0 01.09.23.8.8 0 01-.11.32 1.85 1.85 0 01-.26.37c-.1.11-.18.16-.23.16s-.09 0-.09-.1v-.11h-.07l-.48.35a1 1 0 00-.2.21.37.37 0 00-.08.22.45.45 0 00.13.34.48.48 0 00.35.14 1.08 1.08 0 00.53-.18 5 5 0 00.71-.56z"
          />
          <path
            className="prefix__cls-3"
            d="M50.73 21.72V22a3.8 3.8 0 01-.63.67.86.86 0 01-.51.23.46.46 0 01-.43-.31 1.64 1.64 0 01-.16-.69 1.24 1.24 0 010-.27 1 1 0 01.07-.21 1.84 1.84 0 01.14-.25c.34-.53.64-.8.92-.8s.21.06.21.17a.88.88 0 01-.14.36 1.38 1.38 0 01-.25.35c-.2.2-.33.34-.4.42a.44.44 0 00-.09.27.26.26 0 00.1.21.32.32 0 00.24.09.69.69 0 00.37-.13 4.37 4.37 0 00.56-.39zm-.24-1.6a.31.31 0 01-.17-.07.22.22 0 01-.07-.17.6.6 0 01.14-.35.41.41 0 01.31-.2.21.21 0 01.17.07.24.24 0 01.07.19.54.54 0 01-.14.34.4.4 0 01-.31.19z"
          />
          <path
            className="prefix__cls-3"
            d="M51.11 21.08a4.28 4.28 0 011-.74 2 2 0 01.77-.24.17.17 0 01.14.07.71.71 0 01.1.14.79.79 0 010 .12.12.12 0 01-.05.1l-.07.07.07.15a.64.64 0 00.06.14.44.44 0 010 .1.81.81 0 01-.17.32 2.79 2.79 0 00-.24.35.45.45 0 00-.07.27c0 .18.08.26.24.26a1.48 1.48 0 00.77-.47v.22c-.59.67-1 1-1.27 1a.23.23 0 01-.2-.09.49.49 0 01-.06-.27 2.69 2.69 0 01.2-.82c-.34.38-.59.66-.76.82a.64.64 0 01-.41.25s-.16-.04-.16-.13a1.3 1.3 0 01-.21-.35 1.13 1.13 0 01-.09-.45 1.09 1.09 0 010-.28.89.89 0 01.13-.23 3.47 3.47 0 01.28-.31zm1.68-.4a7.75 7.75 0 00-1.3.73.79.79 0 00-.39.61.38.38 0 000 .19.12.12 0 00.11.07l.13-.05a2.84 2.84 0 00.68-.58c.24-.24.49-.56.77-.97z"
          />
          <path
            className="prefix__cls-3"
            d="M55.3 21.68v.26c-.49.66-.89 1-1.2 1a.46.46 0 01-.39-.28 1.69 1.69 0 01-.14-.73 2.65 2.65 0 01.17-.87 6 6 0 01.45-1 6.68 6.68 0 01.58-.9 2.14 2.14 0 01.57-.54.4.4 0 01.16-.07.1.1 0 01.1.07.6.6 0 010 .23 2.73 2.73 0 01-.4 1.22 7.32 7.32 0 01-1.11 1.48 2 2 0 000 .29.7.7 0 00.09.37.31.31 0 00.27.14.32.32 0 00.19-.07 1.46 1.46 0 00.22-.17zm-1-.65a5 5 0 00.35-.41c.11-.16.22-.32.32-.5a4.28 4.28 0 00.24-.48 1.07 1.07 0 00.08-.31c0-.06 0-.09-.05-.09h-.08a2.18 2.18 0 00-.48.7 4 4 0 00-.43 1.06z"
          />
        </g>
      </g>
    </svg>
  );
}
