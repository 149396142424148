import axios from 'axios';
import { url } from '../components/TopBar/TopBar';
import { getApiBaseURL } from '../constants/baseURL';
import { logApiError } from './handleApiError';

const baseURL = getApiBaseURL();

type ApiInfos = {
  status: string | null;
  version: string | null;
};

export async function getLatestApiInfos(): Promise<ApiInfos> {
  try {
    const response = await axios.get<ApiInfos>(url, {
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response || !response.data)
      throw new Error('no response while querying api info /status');
    console.log('API infos = ', response.data);
    return response.data;
  } catch (err) {
    logApiError(err);
    console.error('Error fetching api version');
    return {
      status: null,
      version: null,
    };
  }
}
