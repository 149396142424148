import React from 'react';

type Props = {
  interval: number;
  children?:
    | React.ReactElement<any>
    | ((name: string | void) => React.ReactNode);
};

export class AutoUpdate extends React.Component<Props> {
  static defaultProps = {
    interval: 1000,
  };

  refreshInterval: null | NodeJS.Timeout = null;

  componentDidMount() {
    const { interval } = this.props;

    this.refreshInterval = setInterval(() => this.forceUpdate(), interval);
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  render() {
    const { children } = this.props;

    if (!children) {
      return null;
    }

    if (typeof children === 'function') {
      return children();
    }

    return React.cloneElement(children as React.ReactElement);
  }
}
