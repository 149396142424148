import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { getToken } from './getToken';
import { Logo } from './logo';
import { getMyUser, UserDetails } from './UserContext/myUser';
import { useAuth } from './UserContext/UserContext';
import { logApiError } from './utils/handleApiError';
import { SnackBarContext } from './utils/snackBarContext';

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/[a-z]+\.[a-z]+/, { message: 'Validation Error' })
    .required(),
  password: Yup.string().required(),
});

export const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<String | null>(null);
  const { addAlert } = useContext(SnackBarContext);

  const { setMyUser } = useAuth();

  const history = useHistory();

  const handleLogin = async () => {
    try {
      const payload = { username, password };
      await ValidationSchema.validate(payload);
      const { data: token } = await getToken({ username, password });
      const userDetails: UserDetails = jwt_decode(token);

      const myInterceptor = axios.interceptors.request.use(
        (config) => {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers['Cache-Control'] = 'no-store';
          if (
            token &&
            config.url &&
            (/aireps/.test(config.url) ||
              /users/.test(config.url) ||
              /adsb/.test(config.url))
          ) {
            config.headers['Authorization'] = 'Bearer ' + token;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          console.log('error message full = ', error);
          if (error?.response?.status === 401) {
            axios.interceptors.request.eject(myInterceptor);
            setMyUser(null);
          }
          if (error?.response?.status === 429) {
            axios.interceptors.request.eject(myInterceptor);
            setMyUser(null);
            addAlert({
              type: 'error',
              message: `too many request from same origin`,
            });
          }
          return Promise.reject(error);
        },
      );

      setMyUser(getMyUser(userDetails));
      history.push('/');
    } catch (error) {
      logApiError(error);
      if (error instanceof Error && error.message === 'Network Error') {
        addAlert({
          type: 'error',
          message: `authentication server unreachable`,
        });
        setError(null);
      } else if (
        error instanceof Error &&
        error.message === 'Validation Error'
      ) {
        setError('invalid format');
      } else {
        setUsername('');
        setPassword('');
        setError('incorrect username or password');
      }
    }
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <form onSubmit={onFormSubmit}>
        <DialogTitle id="form-dialog-title">
          <Box width={280}>
            <Logo />
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="username"
            autoComplete="false"
            onChange={handleUsernameChange}
            value={username}
            error={!!error}
            fullWidth
          />
          <TextField
            margin="dense"
            id="password"
            label="password"
            type="password"
            autoComplete="false"
            onChange={handlePasswordChange}
            value={password}
            error={!!error}
            helperText={error ?? ''}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button color="primary" type="submit">
            Login
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
