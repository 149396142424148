import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../UserContext/UserContext';

type Props = RouteProps & {
  children: React.ReactNode;
};

export const PrivateRoute: React.FC<Props> = ({ children, ...rest }) => {
  const { myUser } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        myUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
